<template>
  <unauthorized
    :authorized="$store.getters.can('create-advances')"
    message="Not Authorized to view Advances"
  >
    <app-loading-indicator v-if="loading" />
    <ValidationObserver
      v-else
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <base-title title="Create Advance" />
      <v-sheet class="pa-4">
        <v-form @submit.prevent="createAdvance">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <div class="subtitle-1">
                General Information
              </div>
              <ValidationProvider
                v-slot="{ errors }"
              >
                <search-autocomplete
                  v-model="lot"
                  label="Related Lot"
                  item-text="friendlyName"
                  item-value="id"
                  api-path="/api/lots/"
                  :params="{ fields: 'id,name', ordering: '-id' }"
                  :format-entry="x => Object.assign({}, { friendlyName: x.name }, x)"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
              >
                <search-autocomplete
                  v-model="createdFor"
                  :params="{ fields: 'username,id', ordering: 'username', is_active: true }"
                  label="Account Manager"
                  api-path="/api/users/"
                  item-value="id"
                  :format-entry="x => ({ friendlyName: x.username, username: x.username, id: x.id })"
                />
              </ValidationProvider>
              <v-divider
                class="ma-4"
              />
              <div class="subtitle-1">
                Payment Details
              </div>
              <rules-text
                validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                label="Amount*"
                v-model="amount"
              />
              <v-radio-group v-model="currency">
                <v-radio
                  v-for="currency in currencies"
                  :key="currency.value"
                  :label="currency.text"
                  :value="currency.value"
                />
              </v-radio-group>
              <rules-date
                validation-rules="required"
                label="Payment Date*"
                v-model="paymentDate"
                :max="new Date().toISOString()"
              />
              <v-divider
                class="ma-4"
              />
              <div class="subtitle-1">
                Documents
              </div>
              <v-file-input
                v-model="proFormaUpload"
                counter
                drop-zone
                show-size
                truncate-length="15"
                hint="blah blah should be PDF/EXCEL and stuff"
              />
            </v-col>
          </v-row>
          <text-fab
            :disabled="invalid"
            bottom
            right
            fixed
            icon-text="mdi-invoice-text-plus"
            color="primary"
            type="submit"
          >
            Create
          </text-fab>
        </v-form>
      </v-sheet>
    </ValidationObserver>
  </unauthorized>
</template>

<script>

import RulesText from '@/components/ValidationFields/RulesText.vue'
import axios from 'axios'
import RulesDate from '@/components/ValidationFields/RulesDate.vue'
import SearchAutocomplete from '@/base/components/SearchAutocomplete.vue'

export default {
  components: { SearchAutocomplete, RulesDate, RulesText },
  data: () => ({
    lot: null,
    amount: null,
    currency: null,
    paymentDate: new Date().toISOString().slice(0, 10),
    createdFor: null,
    proFormaUpload: null,
    loading: false
  }),
  computed: {
    currencies () {
      return this.$store.getters.getCurrencies.filter(x => x.value !== 'NA')
    }
  },
  methods: {
    /**
     * Uploads the userUpload file to AWS
     */
    async uploadProForma (file, advanceId) {
      // Acquire file upload
      const uploadUrlResponse = await axios.post(`/api/advances/${advanceId}/proforma_upload_url/`, {
        filename: file.name
      })
      const proformaObj = uploadUrlResponse.data

      // image upload with headers
      const putResponse = await fetch(proformaObj.upload_url, {
        method: 'PUT',
        headers: {
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Origin': '*',
          'x-amz-acl': 'private',
          'Content-Type': proformaObj.content_type
        },
        body: this.proFormaUpload
      })

      if (putResponse.status !== 200) {
        throw Error('Unable to upload file')
      }
    },
    async postAdvance () {
      const payload = {
        amount: this.amount,
        currency: this.currency,
        payment_date: this.paymentDate
      }
      if (this.createdFor) payload.created_for = this.createdFor
      if (this.lot) payload.lot = this.lot
      return axios.post('/api/advances/', payload)
    },
    async createAdvance () {
      // Create the Advance
      let advance = null
      try {
        const response = await this.postAdvance()
        advance = response.data
        this.$store.commit('setSnackbarSuccess', 'Advance created successfully')
      } catch {
        this.$store.commit('setSnackbarError', 'Failed to create the Advance')
        return
      }
      if (this.proFormaUpload) {
        try {
          await this.uploadProForma(this.proFormaUpload, advance.id)
        } catch (e) {
          this.$store.commit('setSnackbarError', 'Failed to upload Pro Forma Document')
        }
      }
      if (advance) {
        await this.$router.push({ name: 'advance-view', params: { id: advance.id } })
      }
    }
  },
  mounted () {
    this.currency = this.currencies[0].value
  }
}
</script>
